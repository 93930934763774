import * as React from "react";

type Props = {

}

const Lore: React.FC<Props> = (props) => (
    <section className="roadmap" id="lore">
        <div className="container py-10">
            <h2 className="t-center t-shadow">Lore</h2>

            <p className="t-center mb-4">It’s the year 1969 at the peak of the Japan’s Mafia wars, three major clans and bunch of unknowns fight for turf control.  Each Clan has its strength and generates revenue based on its strength. There are some legendary Samurais, and ninjas also disguised as mafia members. The fight for royalties distribution begins.</p>

            <div className="grid gap-x-3">
                <div className="w-1/2@md mb-3">
                    <div className="border bg-foreground h-1/1 d-flex flex-column justify-between">
                        <div className="p-2">
                            <h3>Gumi Clan</h3>
                            <p>The Hyogo-based Yamaguchi-gumi gang are the world's largest crime organization.</p>
                            <p>46% of the Japanese Gangsters, Commonly referred to as the McDonalds of the Yakuza, for its sheer size and reach.</p>
                        </div>

                        <div className="table d-flex flex-wrap">
                            <div>Year of Origin:</div>
                            <div>1915</div>
                            <div>Revenue:</div>
                            <div>Drugs, extortion, laundering, prostitution</div>
                            <div>Traits:</div>
                            <div>Violent, Aggressive</div>
                            <div>Market Share:</div>
                            <div>46%</div>
                        </div>
                    </div>

                </div>

                <div className="w-1/2@md mb-3">
                    <div className="border bg-foreground h-1/1 d-flex flex-column justify-between">
                        <div className="p-2">
                            <h3>Shinoda Clan</h3>
                            <p>Chubu-based Shinoda and its affiliates have about 20% of the Japanese Gangsters.</p>
                            <p>Known for its financial power, real estate</p>
                        </div>

                        <div className="table d-flex flex-wrap">
                            <div>Year of Origin:</div>
                            <div>1934</div>
                            <div>Revenue:</div>
                            <div>Real Estate, Money Laundering, Entertainment, Gambling</div>
                            <div>Traits:</div>
                            <div>Well dressed, Financial Flex</div>
                            <div>Market Share:</div>
                            <div>20%</div>
                        </div>
                    </div>
                </div>

                <div className="w-1/2@md mb-3 mb-0@md">
                    <div className="border bg-foreground h-1/1 d-flex flex-column justify-between">
                        <div className="p-2">
                            <h3>Kodama Clan</h3>
                            <p>Tokyo-based Kodama and its affiliates have about 15% of the Japanese Gangsters, known for political contacts, spies network, anti communist allegiance, doesnt like violence, worked for a peaceful Japan negotiating deals with different factions.</p>
                            <p>Known for its Political power, network of spies, control of Japanese aviation</p>
                        </div>

                        <div className="table d-flex flex-wrap">
                            <div>Year of Origin:</div>
                            <div>1943</div>

                            <div>Revenue:</div>
                            <div>Aviation, Political Brokering, Extortion</div>

                            <div>Traits:</div>
                            <div>Negotiation, Political Contacts</div>

                            <div>Market Share:</div>
                            <div>15%</div>
                        </div>
                    </div>
                </div>

                <div className="w-1/2@md">
                    <div className="border bg-foreground h-1/1 d-flex flex-column justify-between">
                        <div className="p-2">
                            <h3>The Others:</h3>
                            <p>Bunch of freelancers hustling on their own, without any allegiances and for hire.</p>
                            <p>Multi skilled, street smart, known for their cut-throat approach to getting things done, violent and sneaky</p>
                        </div>

                        <div className="table d-flex flex-wrap">
                            <div>Year of Origin:</div>
                            <div>Unknown</div>
                            <div>Revenue:</div>
                            <div>Hire for kill, Contract jobs for the clans</div>
                            <div>Traits:</div>
                            <div>No allegiance</div>
                            <div>Market Share:</div>
                            <div>19%</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
);

export default Lore
