import * as React from "react";

type Props = {}

const Footer: React.FC<Props> = ({children}) => (
    <footer className="footer">
        <div className="container">
            {children}
        </div>
    </footer>
);

export default Footer
