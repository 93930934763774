import {useCallback, useEffect, useMemo, useState} from 'react';
import * as anchor from '@project-serum/anchor';

import styled from 'styled-components';
import { Snackbar} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {PublicKey} from '@solana/web3.js';
import {useWallet} from '@solana/wallet-adapter-react';
import {WalletDialogButton} from '@solana/wallet-adapter-material-ui';
import {
    awaitTransactionSignatureConfirmation,
    CANDY_MACHINE_PROGRAM,
    CandyMachineAccount,
    getCandyMachineState,
    mintOneToken,
} from './candy-machine';
import {AlertState} from './utils';
import {Header} from './Header';
import {MintButton} from './MintButton';
import {GatewayProvider} from '@civic/solana-gateway-react';
import Footer from "./components/Footer";
import Roadmap from "./components/Roadmap";
import Lore from "./components/Lore";

const ConnectButton = WalletDialogButton;

const MintContainer = styled.div``; // add your owns styles here

export interface HomeProps {
    candyMachineId?: anchor.web3.PublicKey;
    connection: anchor.web3.Connection;
    startDate: number;
    txTimeout: number;
    rpcHost: string;
}

const discordLink = 'https://discord.gg/hBZRnP9aqj';
const twitterLink = 'https://twitter.com/YakuzaWarsNFT';

const Home = (props: HomeProps) => {
    const [isUserMinting, setIsUserMinting] = useState(false);
    const [candyMachine, setCandyMachine] = useState<CandyMachineAccount>();
    const [alertState, setAlertState] = useState<AlertState>({
        open: false,
        message: '',
        severity: undefined,
    });

    const rpcUrl = props.rpcHost;
    const wallet = useWallet();

    const anchorWallet = useMemo(() => {
        if (
            !wallet ||
            !wallet.publicKey ||
            !wallet.signAllTransactions ||
            !wallet.signTransaction
        ) {
            return;
        }

        return {
            publicKey: wallet.publicKey,
            signAllTransactions: wallet.signAllTransactions,
            signTransaction: wallet.signTransaction,
        } as anchor.Wallet;
    }, [wallet]);

    const refreshCandyMachineState = useCallback(async () => {
        if (!anchorWallet) {
            return;
        }

        if (props.candyMachineId) {
            try {
                const cndy = await getCandyMachineState(
                    anchorWallet,
                    props.candyMachineId,
                    props.connection,
                );
                setCandyMachine(cndy);
            } catch (e) {
                console.log('There was a problem fetching Candy Machine state');
                console.log(e);
            }
        }
    }, [anchorWallet, props.candyMachineId, props.connection]);

    const onMint = async () => {
        try {
            setIsUserMinting(true);
            document.getElementById('#identity')?.click();
            if (wallet.connected && candyMachine?.program && wallet.publicKey) {
                const mintTxId = (
                    await mintOneToken(candyMachine, wallet.publicKey)
                )[0];

                let status: any = {err: true};
                if (mintTxId) {
                    status = await awaitTransactionSignatureConfirmation(
                        mintTxId,
                        props.txTimeout,
                        props.connection,
                        true,
                    );
                }

                if (status && !status.err) {
                    setAlertState({
                        open: true,
                        message: 'Congratulations! Mint succeeded!',
                        severity: 'success',
                    });
                } else {
                    setAlertState({
                        open: true,
                        message: 'Mint failed! Please try again!',
                        severity: 'error',
                    });
                }
            }
        } catch (error: any) {
            let message = error.msg || 'Minting failed! Please try again!';
            if (!error.msg) {
                if (!error.message) {
                    message = 'Transaction Timeout! Please try again.';
                } else if (error.message.indexOf('0x137')) {
                    message = `SOLD OUT!`;
                } else if (error.message.indexOf('0x135')) {
                    message = `Insufficient funds to mint. Please fund your wallet.`;
                }
            } else {
                if (error.code === 311) {
                    message = `SOLD OUT!`;
                    window.location.reload();
                } else if (error.code === 312) {
                    message = `Minting period hasn't started yet.`;
                }
            }

            setAlertState({
                open: true,
                message,
                severity: 'error',
            });
        } finally {
            setIsUserMinting(false);
        }
    };

    useEffect(() => {
        refreshCandyMachineState();
    }, [
        anchorWallet,
        props.candyMachineId,
        props.connection,
        refreshCandyMachineState,
    ]);


    return (
        <div>
            <header className="banner">
                <nav className="nav d-flex justify-between items-center">
                    <div>
                        <ul className="list-unstyled d-flex">
                            <li><a className="nav__link" href="#team">Team</a></li>
                            <li className="mx-2"><a className="nav__link" href="#roadmap">Roadmap</a></li>
                            <li><a className="nav__link" href="#lore">Lore</a></li>
                        </ul>
                    </div>
                    <div>
                        <a href={discordLink} className="d-inline-block mr-1"><img className="social-icon" alt="discord logo" src="/discord.svg" /></a>
                        <a href={twitterLink} className="d-inline-block"><img className="social-icon" alt="twitter logo" src="/twitter.svg" /></a>
                    </div>
                </nav>

                <div className="container d-flex flex-wrap justify-center pt-4 pb-10">

                    <h1 className="t-center w-1/1 t-shadow mb-2">Yakuza wars</h1>
                    <div className="t-center w-1/1 h3 mb-3">Be a part of the Yakuza and find your clan, then let the clan wars begin!</div>

                    <div className="border p-1 bg-foreground w-1/3@md">
                        <img src="/preview.gif" className="mb-1 mx-auto d-block" alt="nft preview gif" />

                        <h3 className="t-center">Weapons mint now live</h3>

                        {!wallet.connected ? (
                                                    <ConnectButton className="mint-button">Connect Wallet</ConnectButton>
                                                ) : (
                                                    <>
                                                        <Header candyMachine={candyMachine}/>
                                                        <MintContainer>
                                                            {candyMachine?.state.isActive &&
                                                            candyMachine?.state.gatekeeper &&
                                                            wallet.publicKey &&
                                                            wallet.signTransaction ? (
                                                                <GatewayProvider
                                                                    wallet={{
                                                                        publicKey:
                                                                            wallet.publicKey ||
                                                                            new PublicKey(CANDY_MACHINE_PROGRAM),
                                                                        //@ts-ignore
                                                                        signTransaction: wallet.signTransaction,
                                                                    }}
                                                                    gatekeeperNetwork={
                                                                        candyMachine?.state?.gatekeeper?.gatekeeperNetwork
                                                                    }
                                                                    clusterUrl={rpcUrl}
                                                                    options={{autoShowModal: false}}
                                                                >
                                                                    <MintButton
                                                                        candyMachine={candyMachine}
                                                                        isMinting={isUserMinting}
                                                                        onMint={onMint}
                                                                    />
                                                                </GatewayProvider>
                                                            ) : (
                                                                <MintButton
                                                                    candyMachine={candyMachine}
                                                                    isMinting={isUserMinting}
                                                                    onMint={onMint}
                                                                />
                                                            )}
                                                        </MintContainer>
                                                    </>
                                                )}

                    </div>


                    <Snackbar
                        open={alertState.open}
                        autoHideDuration={6000}
                        onClose={() => setAlertState({...alertState, open: false})}
                    >
                        <Alert
                            onClose={() => setAlertState({...alertState, open: false})}
                            severity={alertState.severity}
                        >
                            {alertState.message}
                        </Alert>
                    </Snackbar>
                </div>
            </header>

            <Lore />

            <Footer>
                <section className="team pt-10" id="team">
                    <h2 className="t-center t-shadow">Team</h2>
                    <div className="bg-foreground border px-2 py-2">
                        <div className="grid gap-3 justify-center">
                            <div className="w-1/2@sm w-1/3@md">
                                <img className="mb-1" src="/team-1.png" alt="team member 1" />
                                <div className="h3">Chief Technologist<br />Xerox DB</div>
                            </div>
                            <div className="w-1/2@sm w-1/3@md">
                                <img className="mb-1" src="/team-2.png" alt="team member 2" />
                                <div className="h3">Auditor<br />Trade for Tendies</div>
                            </div>
                            <div className="w-1/2@sm w-1/3@md">
                                <img className="mb-1" src="/team-3.png" alt="team member 1" />
                                <div className="h3">Strategist/Lore<br />Kratos</div>
                            </div>
                            <div className="w-1/2@sm w-1/3@md">
                                <img className="mb-1" src="/team-4.png" alt="team member 1" />
                                <div className="h3">Business Development<br />Bernard "The Caller"</div>
                            </div>
                            <div className="w-1/2@sm w-1/3@md">
                                <img className="mb-1" src="/team-6.png" alt="team member 1" />
                                <div className="h3">Artist<br />Miss Anon</div>
                            </div>
                        </div>
                    </div>
                </section>

                <Roadmap progress={6} />

                <div className="d-flex justify-between items-center pb-1">
                    <div>&copy; Yakuza Wars 2022</div>
                    <div>
                        <a href={discordLink} className="d-inline-block mr-1"><img className="social-icon" alt="discord logo" src="/discord.svg" /></a>
                        <a href={twitterLink} className="d-inline-block"><img className="social-icon" alt="twitter logo" src="/twitter.svg" /></a>
                    </div>
                </div>
            </Footer>
        </div>
    );
};

export default Home;
