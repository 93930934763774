import * as React from "react";

type Props = {
    progress: number
}

const Roadmap: React.FC<Props> = (props) => (
    <section className="roadmap" id="roadmap">
        <div className="py-10">
            <h2 className="t-center t-shadow">Roadmap</h2>

            <div className="border bg-foreground px-2 py-2">
                <h3>Phase 1 - COMPLETE</h3>
                <ol className="m-0">
                    <li>Launch Socials and whitelist forms</li>
                    <li>Shortlist entries from whitelist</li>
                    <li>Airdrop tokens for whitelisted</li>
                    <li>Setup Discord activities for Clan games (Winning clan chooses what to do with Royalties)</li>
                    <li>Voting for Community roles and leadership</li>
                    <li>Virtual Party to close Phase 1</li>
                </ol>

                <h3 className="mt-3">Phase 2</h3>
                <p>Phase 2 encompasses the $YAKUZA tokenomics, NFT staking rewards, and future-gen NFT releases.</p>
                <p>Stay tuned for when we release more info!</p>

                <h3 className="t-center mt-4">Current Stage</h3>
                <div className="d-flex justify-center">
                    <div className="progress-bar w-1/2@md">
                        <div style={ {width: `calc(${((100 / 6) * props.progress) + '%'} - 4px)` } }>
                        </div>
                        <span>
                            { props.progress } / 6
                        </span>
                    </div>
                </div>
            </div>

        </div>
    </section>
);

export default Roadmap
